"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _react = require("react");
var _planner = require("../../utils/planner");
const usePlannerFetcher = (fetcher, loadingLazy) => {
  const {
    0: loadedMonths,
    1: setLoadedMonths
  } = (0, _react.useState)(null);
  const {
    0: loading,
    1: setLoading
  } = (0, _react.useState)(false);
  const isLoaded = (0, _react.useCallback)(month => {
    if (!loadedMonths) {
      return false;
    }
    return (0, _planner.createHasSomeMonth)(loadedMonths)(month);
  }, [loadedMonths]);
  const loadMore = (0, _react.useCallback)(async months => {
    if (loading || loadingLazy) {
      return;
    }
    // we only want to fetch months that aren't loaded yet
    const corrected = months.filter(month => !isLoaded(month));
    if (!corrected.length) {
      return;
    }
    setLoading(true);
    await fetcher(corrected);
    setLoadedMonths(current => [...(current ?? []), ...months]);
    setLoading(false);
  }, [fetcher, isLoaded, loading, loadingLazy]);
  const invalidate = (0, _react.useCallback)(() => setLoadedMonths(null), []);
  return [{
    loadedMonths,
    loading
  }, {
    isLoaded,
    invalidate,
    loadMore
  }];
};
var _default = exports.default = usePlannerFetcher;