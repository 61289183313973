"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = exports.GUTTER_SIZE = void 0;
var _defineProperty2 = _interopRequireDefault(require("@babel/runtime/helpers/defineProperty"));
var _devicesize = require("@oberoninternal/travelbase-ds/context/devicesize");
var _addMonths = _interopRequireDefault(require("date-fns/addMonths"));
var _addYears = _interopRequireDefault(require("date-fns/addYears"));
var _differenceInCalendarMonths = _interopRequireDefault(require("date-fns/differenceInCalendarMonths"));
var _eachMonthOfInterval = _interopRequireDefault(require("date-fns/eachMonthOfInterval"));
var _startOfMonth = _interopRequireDefault(require("date-fns/startOfMonth"));
var _debounce = require("debounce");
var _lodash = _interopRequireDefault(require("lodash.isequal"));
var _react = require("react");
var _useOnclickoutside = _interopRequireDefault(require("use-onclickoutside"));
var _usePrevious = _interopRequireDefault(require("../usePrevious"));
function ownKeys(e, r) { var t = Object.keys(e); if (Object.getOwnPropertySymbols) { var o = Object.getOwnPropertySymbols(e); r && (o = o.filter(function (r) { return Object.getOwnPropertyDescriptor(e, r).enumerable; })), t.push.apply(t, o); } return t; }
function _objectSpread(e) { for (var r = 1; r < arguments.length; r++) { var t = null != arguments[r] ? arguments[r] : {}; r % 2 ? ownKeys(Object(t), !0).forEach(function (r) { (0, _defineProperty2.default)(e, r, t[r]); }) : Object.getOwnPropertyDescriptors ? Object.defineProperties(e, Object.getOwnPropertyDescriptors(t)) : ownKeys(Object(t)).forEach(function (r) { Object.defineProperty(e, r, Object.getOwnPropertyDescriptor(t, r)); }); } return e; } /* eslint-disable no-param-reassign */
const GUTTER_SIZE = exports.GUTTER_SIZE = 32;
const getInterval = () => ({
  start: new Date(),
  end: (0, _addYears.default)(new Date(), 2)
});
const isOverlapping = ([aStart, aEnd], [bStart, bEnd]) => aStart <= bEnd && bStart <= aEnd;
const getActions = (viewportRef, deviceSize, viewMode) => {
  const getMonthElement = (index, viewportEl = viewportRef.current) => viewportEl?.firstChild?.childNodes?.[index];
  const getMonthDimensions = (viewportEl = viewportRef.current) => {
    const monthElement = getMonthElement(0, viewportEl);
    return monthElement ? monthElement.clientWidth : 0;
  };
  const scrollTo = (options, condition = true, viewportEl = viewportRef.current) => {
    if (condition) {
      setTimeout(() => viewportEl?.scrollTo(_objectSpread(_objectSpread({}, options), {}, {
        behavior: 'smooth'
      })));
    }
  };
  const scrollBy = (left, condition = true, viewportEl = viewportRef.current) => {
    if (condition) {
      viewportEl?.scrollBy({
        left,
        behavior: 'smooth'
      });
    }
  };
  const scrollToToday = (condition = true) => {
    scrollTo({
      left: 0
    }, condition);
  };
  const getInViewIndexRange = (viewportEl = viewportRef.current) => {
    const monthElement = getMonthElement(0);
    if (monthElement && viewportEl) {
      const onMobile = deviceSize === 'mobile';
      if (!onMobile || viewMode === 'horizontal') {
        const left = viewportEl.scrollLeft;
        const viewportWidth = viewportEl.clientWidth;
        const size = monthElement.clientWidth + GUTTER_SIZE;
        const firstMonthIndex = Math.ceil(left / size);
        const lastMonthIndex = Math.ceil((left + viewportWidth) / size - 1);
        return [Math.max(firstMonthIndex, 0), Math.max(lastMonthIndex, 0)];
      }
      if (onMobile) {
        const {
          clientHeight
        } = viewportEl;
        const vpRect = viewportEl.getBoundingClientRect();
        const vpStart = vpRect.top;
        const vpEnd = vpStart + clientHeight;
        // hacky AF
        const inViewIndexes = (0, _eachMonthOfInterval.default)(getInterval()).reduce((acc, _, i) => {
          const rect = getMonthElement(i)?.getBoundingClientRect();
          const monthStart = rect?.y ?? 0;
          const monthEnd = rect ? rect.y + rect.height : 0;

          // if these ranges are overlapping it means the current month is in view
          if (isOverlapping([monthStart, monthEnd], [vpStart, vpEnd])) {
            return [...acc, i];
          }
          return acc;
        }, []);
        if (inViewIndexes.length) {
          return [Math.min(...inViewIndexes), Math.max(...inViewIndexes)];
        }
      }
    }
    return [0, 0];
  };
  const jumpToMonth = (date, slow = false, viewportEl = viewportRef.current) => {
    const monthElement = getMonthElement(0, viewportEl);
    const onMobile = deviceSize === 'mobile';
    if (monthElement) {
      const diff = (0, _differenceInCalendarMonths.default)(new Date(date), new Date());
      const scrollMonth = getMonthElement(diff, viewportEl);
      if (!viewportEl || !scrollMonth) {
        return;
      }
      if (!onMobile) {
        // We want to have the startDate as the middle month when there are three months in the viewport, otherwise
        // as the left month
        if (slow) {
          scrollTo({
            left: scrollMonth.offsetLeft
          });
          return;
        }
        viewportEl.scrollLeft = scrollMonth?.offsetLeft;
      }
      if (onMobile) {
        if (slow) {
          scrollTo({
            top: scrollMonth.offsetTop
          });
          return;
        }
        viewportEl.scrollTop = scrollMonth?.offsetTop;
      }
    }
  };
  return {
    getMonthElement,
    getMonthDimensions,
    scrollTo,
    scrollBy,
    scrollToToday,
    getInViewIndexRange,
    jumpToMonth
  };
};
const getInViewInterval = monthsInView => {
  if (!monthsInView) {
    return null;
  }
  let [first, last] = monthsInView;
  if (first > last) {
    [first, last] = [last, first];
  }
  return {
    start: (0, _startOfMonth.default)((0, _addMonths.default)(new Date(), first)),
    end: (0, _startOfMonth.default)((0, _addMonths.default)(new Date(), last))
  };
};
const useOnMonthViewChange = (monthsInView, onInViewIntervalChange) => {
  const prevMonthsInView = (0, _usePrevious.default)(monthsInView);
  (0, _react.useEffect)(() => {
    if (!(0, _lodash.default)(prevMonthsInView, monthsInView)) {
      const newInViewInterval = getInViewInterval(monthsInView);
      if (newInViewInterval) {
        onInViewIntervalChange?.((0, _eachMonthOfInterval.default)(newInViewInterval));
      }
    }
  }, [monthsInView, onInViewIntervalChange, prevMonthsInView]);
};
const usePlannerView = (options = {}) => {
  const {
    onClickOutside,
    onInViewIntervalChange
  } = options;
  // initial values shouldn't change so store it in a ref
  const initialCenterMonth = (0, _react.useRef)(options.initialCenterMonth);
  const viewportRef = (0, _react.useRef)(null);
  const months = (0, _eachMonthOfInterval.default)(getInterval());
  const deviceSize = (0, _devicesize.useDeviceSize)();
  const viewActions = (0, _react.useMemo)(() => getActions(viewportRef, deviceSize, options?.mode ?? 'vertical'), [deviceSize, options?.mode]);
  const {
    0: monthsInView,
    1: setMonthsInView
  } = (0, _react.useState)(null);
  useOnMonthViewChange(monthsInView, onInViewIntervalChange);
  const setNewMonthsInView = (0, _react.useCallback)(() => {
    const inView = viewActions.getInViewIndexRange();
    if (!(0, _lodash.default)(monthsInView, inView)) {
      setMonthsInView(inView);
    }
  }, [viewActions, monthsInView]);
  (0, _react.useEffect)(() => {
    if (deviceSize) {
      setNewMonthsInView();
    }
  }, [deviceSize, setNewMonthsInView]);
  (0, _useOnclickoutside.default)(viewportRef, () => {
    onClickOutside?.();
  });

  // TODO use useMemo in order te create the function
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const onScrollHandler = (0, _react.useCallback)((0, _debounce.debounce)(setNewMonthsInView), [setNewMonthsInView]);
  const viewportRefCallback = (0, _react.useCallback)(node => {
    if (!node) {
      return;
    }
    if (initialCenterMonth.current) {
      viewActions.jumpToMonth(initialCenterMonth.current, undefined, node);
    }
    // eslint-disable-next-line no-param-reassign
    viewportRef.current = node;
  }, [viewActions]);
  (0, _react.useEffect)(() => {
    viewportRef.current?.addEventListener('scroll', onScrollHandler);
    return () => viewportRef.current?.removeEventListener('scroll', onScrollHandler);
  }, [onScrollHandler, setNewMonthsInView]);
  const inViewInterval = (0, _react.useMemo)(() => getInViewInterval(monthsInView), [monthsInView]);
  return [{
    inViewInterval,
    monthsInView,
    months,
    viewportRef: viewportRefCallback,
    canGoBackwards: monthsInView?.[0] !== 0,
    canGoForwards: monthsInView?.[1] !== months.length - 1
  }, viewActions];
};
var _default = exports.default = usePlannerView;