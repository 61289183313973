"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.getPeriodColor = exports.createHasSomeMonth = exports.correctVal = exports.areSamePeriods = void 0;
var _isSameMonth = _interopRequireDefault(require("date-fns/isSameMonth"));
var _graphql = require("../generated/graphql");
var _isDate = _interopRequireDefault(require("./isDate"));
// new Date() === new Date() -> false, so thats why we compare the numeric values of the date
const correctVal = val => (0, _isDate.default)(val) ? +val : val;
exports.correctVal = correctVal;
const areSamePeriods = (periodA, periodB) => {
  const {
    start: startA,
    end: endA
  } = periodA ?? {};
  const {
    start: startB,
    end: endB
  } = periodB ?? {};
  return correctVal(startA) === correctVal(startB) && correctVal(endA) === correctVal(endB);
};
exports.areSamePeriods = areSamePeriods;
const getPeriodColor = (selectedTrips, specialFilter) => {
  const firstSelectedTrip = selectedTrips[0];
  const {
    type
  } = firstSelectedTrip ?? {};
  if (type === _graphql.TripTypeEnum.EarlyBooking || type === _graphql.TripTypeEnum.LastMinute || specialFilter) {
    return 'primary';
  }
  return 'secondary';
};
exports.getPeriodColor = getPeriodColor;
const createHasSomeMonth = months => date => months.some(month => (0, _isSameMonth.default)(month, date));
exports.createHasSomeMonth = createHasSomeMonth;