"use strict";

Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _client = require("@apollo/client");
var _react = require("react");
const usePlannerQuery = (variables, document) => {
  const client = (0, _client.useApolloClient)();
  const writeQuery = (0, _react.useCallback)(newData => {
    client.writeQuery({
      query: document,
      variables,
      data: newData
    });
  }, [client, document, variables]);
  const readQuery = (0, _react.useCallback)(() => {
    try {
      return client.readQuery({
        query: document,
        variables
      });
    } catch (error) {
      return null;
    }
  }, [client, document, variables]);
  const query = (0, _react.useCallback)(vars => client.query({
    variables: vars,
    query: document,
    fetchPolicy: 'network-only'
  }), [client, document]);
  const result = (0, _client.useQuery)(document, {
    variables,
    ssr: false
  });
  const data = (0, _react.useMemo)(() => result.data, [result.data]);
  return (0, _react.useMemo)(() => [{
    data,
    variables
  }, {
    writeQuery,
    readQuery,
    query
  }], [data, query, readQuery, writeQuery, variables]);
};
var _default = exports.default = usePlannerQuery;