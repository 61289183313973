"use strict";

var _interopRequireDefault = require("@babel/runtime/helpers/interopRequireDefault");
Object.defineProperty(exports, "__esModule", {
  value: true
});
exports.default = void 0;
var _isAfter = _interopRequireDefault(require("date-fns/isAfter"));
var _isSameDay = _interopRequireDefault(require("date-fns/isSameDay"));
var _useQueryParams = require("use-query-params");
var _isDate = _interopRequireDefault(require("./isDate"));
/**
 * The DataParam will decode succesfully in case of the following
 * - Value is a string
 * - Value is a date in string format or an empty string meaning no date is selected
 * - Date is today or in the future
 *
 */
const DateParam = {
  encode: _useQueryParams.encodeString,
  decode: val => {
    const decodedVal = (0, _useQueryParams.decodeString)(val);
    if (typeof decodedVal !== 'string' || decodedVal === '') {
      return decodedVal ?? undefined;
    }
    const today = new Date();
    const dateVal = new Date(decodedVal);
    if ((0, _isDate.default)(dateVal) && ((0, _isSameDay.default)(today, dateVal) || (0, _isAfter.default)(dateVal, today))) {
      return decodedVal;
    }
    return undefined;
  }
};
var _default = exports.default = DateParam;